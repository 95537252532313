@font-face {
  font-family: 'AvenirNext';
  src: url('./fonts/AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./fonts/AvenirNextLTPro-Demi.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: 700;
}
