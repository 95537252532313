*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'AvenirNext', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-fluid {
  max-width: 72.75em;

  @media (max-width: 767px) {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.row {

  @media (min-width: 768px) {
    margin-left: -1.5625em;
    margin-right: -1.5625em;

    > [class*='col'] {
      padding-left: 1.5625em;
      padding-right: 1.5625em;
    }
  }
}
